import React, { useEffect, useState } from "react";
import style from "./style.module.scss";
import { SortDescriptor } from "@progress/kendo-data-query";
import {
  AdminContentWrapper,
  AdminHeader,
  Loader,
  TableGrid,
} from "../../components";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  changeReclamationItemsStatuses,
  getAdminReclamation,
} from "../../store/slices/reclamation";
import { format } from "date-fns";
import { statuses } from "../AdminSperrmuelanmeldungen";
import appConfig from "../../util/appConfig";
import exploreArrow from "../../assets/images/exploreArrow.svg";
import { GridCellProps, GridColumn } from "@progress/kendo-react-grid";
import { useNavigate } from "react-router-dom";

const AdminReclamation = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isAdminLoaderActive } = useAppSelector((state) => state.admin);
  const { itemsCount, reclamationData } = useAppSelector(
    (state) => state.reclamation.admin
  );

  const [sort, setSort] = useState<Array<SortDescriptor>>([]);
  const [value, setValue] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [datesChanged, setDatesChanged] = useState({});
  const [status, setStatus] = useState<string[]>([]);
  const [abfallartType, setAbfallartType] = useState<string[]>([]);

  const onDateChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (end) {
      setDatesChanged({ updated: true });
    }
  };

  const changeHandler = (val: string) => {
    setValue(val);
  };

  const pageChangeHandler = (page: number) => {
    setPage(page);
  };

  const pageSizeHandler = (size: number) => {
    setPage(1);
    setPageSize(size);
  };

  const changeStatuses = (st: string[]) => {
    setStatus([...st]);
  };

  const setAcceptedStatus = () => {
    if (["calw"].includes(appConfig.logo)) {
      dispatch(
        changeReclamationItemsStatuses({
          ids: reclamationData.map((el) => el.id),
          status: "accepted",
        })
      );
      return;
    }
  };

  const generateSelectOptions = (type: string) => {
    switch (type) {
      case "calw": {
        return [
          { label: statuses.accepted, value: "accepted" },
          { label: statuses.inWork, value: "inWork" },
          { label: statuses.declined, value: "declined" },
        ];
      }
      default: {
        return [
          { label: statuses.accepted, value: "accepted" },
          { label: statuses.inWork, value: "inWork" },
        ];
      }
    }
  };

  const LastItem = (props: GridCellProps) => {
    return (
      <td
        className="k-table-td"
        style={{
          border: "none",
        }}
        key={Math.floor(Math.random() * 1000000000000)}
      >
        <div
          className={style.detailBtn}
          onClick={() => {
            navigate(props.dataItem.id);
          }}
        >
          <img src={exploreArrow} alt="info" />
        </div>
      </td>
    );
  };

  const generateAbfallArtTypes = (type: string) => {
    switch(type) {
      case 'calw': {
        return [
          { label: 'Restmüll', value: "Restmüll" },
          { label: 'Biomüll', value: "Biomüll" },
          { label: 'Glas', value: "Glas" },
          { label: 'Papier, Pappe, Kartonage', value: "Papier, Pappe, Kartonage" },
        ]
      }
      default: {
        return []
      }
    }
  }

  useEffect(() => {
    const abortController = new window.AbortController();
    const signal = abortController.signal;

    dispatch(
      getAdminReclamation({
        direction: sort[0]?.dir,
        page,
        pageSize,
        searchValue: value,
        sort: sort[0]?.field,
        signal,
        startDate,
        endDate,
        status,
      })
    );

    return () => {
      if (signal && abortController.abort) {
        abortController.abort();
      }
    };
  }, [sort, page, pageSize, value, datesChanged, status]);

  return (
    <div>
      <AdminHeader pageTitle={"Reklamation"} hasBtnGroup={false} />
      <AdminContentWrapper>
        <TableGrid
          data={reclamationData.map((el) => {
            return {
              ...el,
              street: `${el.place}, ${el.street} ${el.homeNumber}${
                el?.hausNrZusatz ? ` ${el?.hausNrZusatz}` : ""
              }`,
              name: `${el.userSurname}, ${el.userName} `,
              createdAt: el.createdAt
                ? format(new Date(el.createdAt), "dd.MM.yyyy hh:mm")
                : "",
              time: el.createdAt ? format(new Date(el.createdAt), "hh:mm") : "",
              // date: el.date ? format(new Date(el.date), "dd.MM.yyyy") : "",
              date: format(new Date(el.date), "dd.MM.yyyy"),
              status: el.status ? statuses[el.status] : "",
            };
          })}
          sort={sort}
          sortable={true}
          onSortChange={setSort}
          withExcelExport={true}
          items={[
            { field: "status", title: "Status" },
            { field: "createdAt", title: "Datum (Meldung)" },
            { field: "name", title: "Nachname, Vorname" },
            { field: "street", title: "Straße" },
            { field: "place", title: "Ort" },
            { field: "type", title: "Abfallart" },
            { field: "reclamationReason", title: "Reklamationsgrund" },
            { field: "date", title: "Leistungsdatum" },
          ]}
          searchInputValue={value}
          onSearchInputValueChange={changeHandler}
          withPager={true}
          page={page}
          pageSize={pageSize}
          pageChangeHandler={pageChangeHandler}
          pageSizeHandler={pageSizeHandler}
          itemsCount={itemsCount}
          onDateChange={onDateChange}
          startDate={startDate}
          endDate={endDate}
          withDatePicker
          withSelect={["calw"].includes(appConfig.logo)}
          selectOptions={generateSelectOptions(appConfig.logo).filter(
            (el) => !status.includes(el.value)
          )}
          setValue={changeStatuses}
          value={status.map((el) => {
            return {
              value: el,
              label: statuses[el],
            };
          })}
          onExcelExportClickHandler={setAcceptedStatus}
          lastItem={<GridColumn cell={LastItem} width="50px" />}
          withAbfallart={["calw"].includes(appConfig.logo)}
          selectAbfallartTypeOptions={generateAbfallArtTypes(appConfig.logo)}
        />
      </AdminContentWrapper>
      {isAdminLoaderActive && <Loader />}
    </div>
  );
};

export default AdminReclamation;
