import React, { useEffect, useState } from "react";
import style from "./style.module.scss";
import { SortDescriptor } from "@progress/kendo-data-query";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  changeItemsStatuses,
  getAdminBehaeltergemeinschaft,
} from "../../store/slices/behaeltergemeinschaft";
import {
  AdminContentWrapper,
  AdminHeader,
  Loader,
  TableGrid,
} from "../../components";
import { format } from "date-fns";
import { GridCellProps, GridColumn } from "@progress/kendo-react-grid";
import exploreArrow from "../../assets/images/exploreArrow.svg";
import { useNavigate } from "react-router-dom";

export const statuses: { [key: string]: string } = {
  accepted: "Fertig",
  declined: "Storniert",
  inWork: "Offen",
};

const AdminBehaeltergemeinschaft = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isAdminLoaderActive } = useAppSelector((state) => state.admin);
  const { behaeltergemeinschaftData, totalCount } = useAppSelector(
    (state) => state.behaeltergemeinschaft.admin
  );

  const [sort, setSort] = useState<Array<SortDescriptor>>([]);
  const [value, setValue] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [datesChanged, setDatesChanged] = useState({});
  const [status, setStatus] = useState<string[]>([]);

  const onDateChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (end) {
      setDatesChanged({ updated: true });
    }
  };

  const changeHandler = (val: string) => {
    setValue(val);
  };

  const pageChangeHandler = (page: number) => {
    setPage(page);
  };

  const pageSizeHandler = (size: number) => {
    setPage(1);
    setPageSize(size);
  };

  const changeStatuses = (st: string[]) => {
    setStatus([...st]);
  };

  const setAcceptedStatus = () => {
    // dispatch(
    //   changeItemsStatuses({
    //     ids: behaeltergemeinschaftData.map((el) => el.id),
    //     status: "accepted",
    //   })
    // );
  };

  useEffect(() => {
    const abortController = new window.AbortController();
    const signal = abortController.signal;

    dispatch(
      getAdminBehaeltergemeinschaft({
        direction: sort[0]?.dir,
        page,
        pageSize,
        searchValue: value,
        sort: sort[0]?.field,
        signal,
        startDate,
        endDate,
        status,
      })
    );

    return () => {
      if (signal && abortController.abort) {
        abortController.abort();
      }
    };
  }, [sort, page, pageSize, value, datesChanged, status]);

  const LastItem = (props: GridCellProps) => {
    return (
      <td
        className="k-table-td"
        style={{
          border: "none",
        }}
        key={Math.floor(Math.random() * 1000000000000)}
      >
        <div
          className={style.detailBtn}
          onClick={() => {
            navigate(props.dataItem.id);
          }}
        >
          <img src={exploreArrow} alt="info" />
        </div>
      </td>
    );
  };

  return (
    <div>
      <AdminHeader pageTitle={"Behältergemeinschaft"} hasBtnGroup={false} />
      <AdminContentWrapper>
        <TableGrid
          data={behaeltergemeinschaftData.map((el) => {
            return {
              ...el,
              createdAt: el.createdAt
                ? format(new Date(el.createdAt), "dd.MM.yyyy hh:mm")
                : "",
              owner: `${el.ownerName}, ${el.ownerSurname} `,
              couser: `${el.couserName}, ${el.couserSurname} `,
              status: statuses[el.status],
            };
          })}
          sort={sort}
          sortable={true}
          onSortChange={setSort}
          withExcelExport={true}
          items={[
            { field: "status", title: "Status" },
            { field: "createdAt", title: "Datum (Meldung)" },
            { field: "ownerBookingCode", title: "Buchungszeichen" },
            {
              field: "owner",
              title: "Tonnenbesitzer/Rechnungsempfänger",
            },
            { field: "couser", title: "Tonnen-Mitnutzer" },
            { field: "type", title: "Typ" },
          ]}
          searchInputValue={value}
          onSearchInputValueChange={changeHandler}
          withPager={true}
          page={page}
          pageSize={pageSize}
          pageChangeHandler={pageChangeHandler}
          pageSizeHandler={pageSizeHandler}
          itemsCount={totalCount}
          onDateChange={onDateChange}
          startDate={startDate}
          endDate={endDate}
          withDatePicker
          withSelect
          selectOptions={[
            { label: statuses.accepted, value: "accepted" },
            // { label: statuses.declined, value: "declined" },
            { label: statuses.inWork, value: "inWork" },
          ].filter((el) => !status.includes(el.value))}
          setValue={changeStatuses}
          value={status.map((el) => {
            return {
              value: el,
              label: statuses[el],
            };
          })}
          lastItem={<GridColumn cell={LastItem} width="50px" />}
          onExcelExportClickHandler={setAcceptedStatus}
        />
      </AdminContentWrapper>
      {isAdminLoaderActive && <Loader />}
    </div>
  );
};

export default AdminBehaeltergemeinschaft;
