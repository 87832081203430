import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api/api";
import { setIsLoading, setIsToasterError } from "./app";

export interface IUser {
  firstName: string;
  lastName: string;
  city: string;
  street: string;
  postCode: string;
  email: string;
  errorMsg: string;
}

const initialState: IUser = {
  firstName: "",
  lastName: "",
  city: "",
  street: "",
  postCode: "",
  email: "",
  errorMsg: "",
};

export const loginUser = createAsyncThunk(
  "user/login",
  async (code: string, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));
      const data = API.auth.post.nrwLogin(code);
      thunkAPI.dispatch(setIsLoading(false));
      return data;
    } catch (error) {
      thunkAPI.dispatch(setIsLoading(false));
      thunkAPI.dispatch(setIsToasterError(false));
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loginUser.fulfilled, (state, action) => {
      if (action.payload.message && action.payload.message.length) {
        state.errorMsg = action.payload.message;
      } else {
        state.street = action.payload?.street!;
        state.city = action.payload?.city!;
        state.email = action.payload?.email!;
        state.firstName = action.payload?.firstName!;
        state.lastName = action.payload?.lastName!;
        state.postCode = action.payload?.postCode!;
      }
    });
  },
});

export const userReducer = userSlice.reducer;
