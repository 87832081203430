import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { BurgerMenu, Header, Loader } from "../../components";
import ActualNews from "../../pages/ActualNews";
import ActualNewsArticle from "../../pages/ActualNewsArticle";
import appConfig from "../../util/appConfig";

const BulkyTrashRegistration = lazy(() => import("../../pages/BulkyTrash"));
const Calendar = lazy(() => import("../../pages/Calendar"));
const Main = lazy(() => import("../../pages/Main"));
const Login = lazy(() => import("../../pages/Login"));
const Registration = lazy(() => import("../../pages/Registration"));
const Leerungsdaten = lazy(() => import("../../pages/Leerungsdaten"));
const Profile = lazy(() => import("../../pages/Profile"));
const RecycleCenterAppointment = lazy(
  () => import("../../pages/RecycleCenterAppointment")
);
const Schadstoffmobil = lazy(() => import("../../pages/Schadstoffmobil"));
const Privacy = lazy(() => import("../../pages/DataPrivacy"));
const Reclamation = lazy(() => import("../../pages/Reclamation"));
const Behaelterverwaltung = lazy(
  () => import("../../pages/Behaelterverwaltung")
);
const Eigenkompostierer = lazy(() => import("../../pages/Eigenkompostierer"));
const YellowBin = lazy(() => import("../../pages/YellowBin"));
const Behaeltergemeinschaft = lazy(
  () => import("../../pages/Behaeltergemeinschaft")
);
const Contact = lazy(() => import("../../pages/Contact"));
const Imprint = lazy(() => import("../../pages/Imprint"));
const LeerungsdatenEinsicht = lazy(
  () => import("../../pages/LeerungsdatenEinsicht")
);
const SepaManagement = lazy(() => import("../../pages/SepaManagement"));

const MainContent = () => {
  return (
    <>
      <Header />
      <div className={"mainWrapper"}>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="login" element={<Login />} />

            <Route path="registration" element={<Registration />} />
            {["sensis", "awl", "bk", "ebwo", "lra", "calw"].includes(
              appConfig.logo
            ) && (
              <Route
                path="sperrmuell-anmelden"
                element={<BulkyTrashRegistration />}
              />
            )}
            {["ebwo"].includes(appConfig.logo) && (
              <Route
                path="blitzsperrmuell-anmelden"
                element={<BulkyTrashRegistration />}
              />
            )}
            {["sensis", "awl", "bk"].includes(appConfig.logo) && (
              <Route path="calendar" element={<Calendar />} />
            )}
            {["sensis", "lra", "calw"].includes(appConfig.logo) && (
              <Route path="leerungsdaten" element={<Leerungsdaten />} />
            )}
            {["sensis"].includes(appConfig.logo) && (
              <Route path="profile" element={<Profile />} />
            )}
            {["sensis"].includes(appConfig.logo) && (
              <Route path="actual" element={<ActualNews />} />
            )}
            {["sensis"].includes(appConfig.logo) && (
              <Route path="actual/:articleId" element={<ActualNewsArticle />} />
            )}

            {["sensis"].includes(appConfig.logo) && (
              <Route
                path="wertstoffhoftermine"
                element={<RecycleCenterAppointment />}
              />
            )}
            {["sensis"].includes(appConfig.logo) && (
              <Route path="schadstoffmobil" element={<Schadstoffmobil />} />
            )}
            <Route path="privacy" element={<Privacy />} />
            {["lra", "calw".includes(appConfig.logo)] && (
              <Route path="reklamation" element={<Reclamation />} />
            )}
            {["lra", "calw", "sensis"].includes(appConfig.logo) && (
              <Route
                path="behaelterverwaltung"
                element={<Behaelterverwaltung />}
              />
            )}
            {["calw"].includes(appConfig.logo) && (
              <Route path="eigenkompostierer" element={<Eigenkompostierer />} />
            )}
            {["calw"].includes(appConfig.logo) && (
              <Route path="gelbe-tonne" element={<YellowBin />} />
            )}
            {["calw"].includes(appConfig.logo) && (
              <Route
                path="behaeltergemeinschaft"
                element={<Behaeltergemeinschaft />}
              />
            )}
            {["calw"].includes(appConfig.logo) && (
              <Route
                path="leerungsdaten-einsicht"
                element={<LeerungsdatenEinsicht />}
              />
            )}
            {["lra"].includes(appConfig.logo) && (
              <Route path="sepa-management" element={<SepaManagement />} />
            )}
            <Route path="contact" element={<Contact />} />
            <Route path="impressum" element={<Imprint />} />
          </Routes>
        </Suspense>
      </div>
      <BurgerMenu />
    </>
  );
};

export default MainContent;
